import { Constants } from '../actions/notification';

const initialState = {
    notification: [],
    businessChannel: [],
};

export default function (state = initialState, action) {
    switch (action.type) {
        case Constants.getNotificationsSuccess:
            return {
                ...state,
                notification: action.response ? action.response : []
            };
        case Constants.getBusinessChannelSuccess:
            return {
                ...state,
                businessChannel: action.response
            };
        default:
            return state;
    }
}
