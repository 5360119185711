import { CALL_API } from '../libraries/middleware';
import actionCreator from '../libraries/actionCreator';


interface CouponObject {
    body: object;
    couponId: number;
}

export const {Actions, Constants} = actionCreator({

    getCoupons() {
        return {
            [CALL_API]: {
                method: 'GET',
                path: `/coupon`
            }
        }
    },
    addCoupon(formData: CouponObject) {
        const {body} = formData;
        return {
            [CALL_API]: {
                method: 'POST',
                path: `/coupon`,
                body: body
            }
        }
    },
    updateCoupon(formData: CouponObject) {
        const {body} = formData;
        return {
            [CALL_API]: {
                method: 'PUT',
                path: `/coupon`,
                body: body
            }
        }
    },
    deleteCoupon(formData: CouponObject) {
        const {couponId} = formData;
        return {
            [CALL_API]: {
                method: 'DELETE',
                path: `/coupon/${couponId}`,
            }
        }
    },

    getCouponDetail(formData: CouponObject) {
        const {couponId} = formData;
        return {
            [CALL_API]: {
                method: 'GET',
                path: `/coupon/detail/${couponId}`
            }
        }
    },

    exportCouponDetail(formData: CouponObject) {
        const {couponId} = formData;
        return {
            [CALL_API]: {
                method: 'POST',
                path: `/coupon/detail/export/${couponId}`,
                apiType: 'binary'
            }
        }
    },
    importCirculation(formData: any, couponId: string) {
        return {
            [CALL_API]: {
                method: 'POST',
                path: `/coupon/detail/importCirculation/${couponId}`,
                body: formData,
                apiType: 'multipart'
            }
        }
    },
  checkCustomizeCode(code: string) {
    return {
      [CALL_API]: {
        method: 'GET',
        path: `/coupon/check-customize-code/${code}`
      }
    }
  },

});
