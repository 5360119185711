import { Constants } from '../actions/common';

const initialState = {
    isApiAnimationOpen: false,
    windowInnerWidth: window.innerWidth
};

export default function (state = initialState, action) {
    switch (action.type) {
        case Constants.openLoadingAnimation:
            return {
                ...state,
                isApiAnimationOpen: true
            };
        case Constants.closeLoadingAnimation:
            return {
                ...state,
                isApiAnimationOpen: false
            };
        case Constants.changeWindowSize:
            return {
                ...state,
                windowInnerWidth: action.windowInnerWidth
            };
        default:
            return state;
    }
}
