import { CALL_API } from '../libraries/middleware';
import actionCreator from '../libraries/actionCreator';

export const { Actions, Constants } = actionCreator({

    getBusinessPointGroup() {
        return {
            [CALL_API]: {
                method: 'GET',
                path: `/point/group`
            }
        }
    },

    savePointGroup(data: object) {
        return {
            [CALL_API]: {
                method: 'POST',
                path: `/point/group`,
                body: data,
            }
        };
    },

    deletePointGroup(id: number) {
        return {
            [CALL_API]: {
                method: 'DELETE',
                path: `/point/group/${id}`,
            }
        };
    },

    getPointInfo(data: Object) {
        return {
            [CALL_API]: {
                method: 'GET',
                path: `/point/info`,
                query: data,
            }
        };
    },

    updatePoint(data: Object) {
        return {
            [CALL_API]: {
                method: 'POST',
                path: `/point/update`,
                query: data,
            }
        };
    }

});