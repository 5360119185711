import * as superAgent from "superagent";
import * as _ from "lodash";
import * as path from "path";
import { APIConfig } from "../constants/apiconfig";

export const CALL_API = "CALL_API";

_.noConflict();
const noopDefaultParams = () => { };

export default ({
    errorInterceptor,
    baseUrl,
    generateDefaultParams = noopDefaultParams,
    defaultProcessBeforeRequest = noopDefaultParams,
    defaultProcessAfterSuccess = noopDefaultParams,
    defaultProcessAfterError = noopDefaultParams,
    defaultSendingType,
    validateResponseBody
}) => {

    const extractParams = paramsExtractor({
        baseUrl
    });

    return ({
        dispatch,
        getState
    }) => next => action => {
        if (action[CALL_API]) {
            const createCallApiAction = () => action;
            return createRequestPromise({
                generateDefaultParams,
                defaultProcessBeforeRequest,
                defaultProcessAfterSuccess,
                defaultProcessAfterError,
                defaultSendingType,
                validateResponseBody,
                createCallApiAction,
                getState,
                dispatch,
                errorInterceptor,
                extractParams
            });
        } else {
            return next(action);
        }
    };
};

function actionWith(action, toMerge) {
    const ac = _.cloneDeep(action);
    if (ac[CALL_API]) {
        delete ac[CALL_API];
    }
    return _.merge(ac, toMerge);
}

function createRequestPromise({
    generateDefaultParams,
    defaultProcessBeforeRequest,
    defaultProcessAfterSuccess,
    defaultProcessAfterError,
    defaultSendingType,
    validateResponseBody,
    createCallApiAction,
    getState,
    dispatch,
    errorInterceptor,
    extractParams
}) {
    return new Promise((resolve, reject) => {
        let apiAction = createCallApiAction();
        let params = extractParams(apiAction[CALL_API]);

        // reConstruct type string to Object
        function reConstructTypeToObjet(type) {
            if (_.isString(type)) {
                return {
                    type
                };
            }
            return type;
        }

        params.sendingType = reConstructTypeToObjet(params.sendingType);
        params.successType = reConstructTypeToObjet(params.successType);
        params.errorType = reConstructTypeToObjet(params.errorType);

        function sendRequest() {
            defaultProcessBeforeRequest(dispatch, getState);

            if (params.sendingType) {
                dispatch({
                    ...apiAction[CALL_API],
                    ...params.sendingType
                });
            } else if (typeof defaultSendingType === 'function') {
                dispatch({
                    ...apiAction[CALL_API],
                    ...defaultSendingType()
                });
            }

            let defaultParams = getExtendedParams();
            let customHeaders = params.headers || {};
            let method = params.method.toLowerCase();
            method = method === 'delete' ? 'del' : method;

            let request = superAgent[method](params.baseUrl + params.path);
            // request.timeout(1000 * 120);
            request.timeout({ deadline: 1000 * 60 * 5 });
            if (process.env.REACT_APP_ALLOW_CROSS_ORIGIN !== 'true' && _.isFunction(request.withCredentials)) {
                request = request.withCredentials();
            }
            if (params.apiType === 'binary') {
                request = request.responseType('blob');
            }
            let queryObject = Object.assign({}, defaultParams.query, params.query);
            let sendObject;
            if (params.body instanceof Array) {
                sendObject = params.body;
            } else {
                sendObject = Object.assign({}, defaultParams.body, params.body);

            }

            if (params.apiType === 'multipart') {
                delete defaultParams.headers['Content-Type'];
                for (let key in sendObject) {
                    if (sendObject[key] && sendObject[key] instanceof File) {
                        request.attach(key, sendObject[key]);
                    } else if (sendObject[key]) {
                        request.field(key, sendObject[key]);
                    }
                }
            } else {

                request.send(sendObject);
            }

            request
                .set(defaultParams.headers)
                .set(customHeaders)
                .query(queryObject)
                .end((err, res) => {
                    function proceedError() {
                        handleError(err);
                    }
                    if (err) {
                        if (_.isFunction(errorInterceptor)) {
                            errorInterceptor({
                                proceedError,
                                err,
                                dispatch,
                                replay
                            });
                        } else {
                            proceedError();
                        }
                        reject(res);
                    } else {
                        let resBody = res.body;
                        if (_.isFunction(validateResponseBody)) {
                            if (validateResponseBody(resBody, dispatch)) {
                                dispatchSuccessType(resBody);
                                processAfterSuccess(resBody);
                                resolve(resBody);
                            } else {
                                if (resBody != null && resBody.message) {
                                    reject(new Error(resBody.message));
                                } else if (resBody != null) {
                                    reject(new Error(`Server error, err code = ${resBody.errorCode || 'unknown'}`));
                                } else {
                                    dispatchSuccessType(resBody);
                                    processAfterSuccess(resBody);
                                    resolve(resBody);
                                }
                            }
                        }
                    }
                });

        }
        sendRequest();

        function replay() {
            dispatch(apiAction);
        }

        function handleError(err) {
            if ((err.status === undefined || err.status === 404) && process.env.REACT_APP_CALL_MOCK_JSON_WHEN_API_FAIL === 'true') {
                const apiMappingKey = params.path;
                if (APIConfig[apiMappingKey]) {
                    for (const property in APIConfig[apiMappingKey]) {
                        APIConfig[apiMappingKey][property.toLowerCase()] = APIConfig[apiMappingKey][property];
                    }
                    const jsonPath = APIConfig[apiMappingKey][params.method.toLowerCase()];
                    const dir = path.resolve(__dirname, './apiMockJson/' + jsonPath);
                    superAgent.get(dir).end((err, res) => {
                        const resBody = res.body;
                        if (_.isFunction(validateResponseBody) && validateResponseBody(apiAction, resBody, dispatch)) {
                            dispatchSuccessType(resBody);
                            processAfterSuccess(resBody);
                            resolve(resBody);
                        }
                    });
                }
            } else {
                dispatchErrorType(err);
                processAfterError(err);
            }
        }

        function dispatchErrorType(err) {
            if (params.errorType) {
                dispatch(actionWith(apiAction, {
                    ...params.errorType,
                    error: err
                }));
            }
        }

        function processAfterError(response) {
            defaultProcessAfterError(dispatch, getState);
            if (_.isFunction(params.afterError)) {
                params.afterError({
                    getState,
                    dispatch,
                    response
                });
            }
        }

        function dispatchSuccessType(response) {
            dispatch(actionWith(apiAction, {
                ...params.successType,
                response
            }));
            defaultProcessAfterSuccess(dispatch, apiAction, getState);
        }

        function processAfterSuccess(response) {
            if (_.isFunction(params.afterSuccess)) {
                params.afterSuccess({
                    getState,
                    dispatch,
                    response
                });
            }
        }

        function getExtendedParams() {
            let { headers, body, query } = generateDefaultParams();
            headers = headers || {};
            body = body || {};
            query = query || {};
            return {
                headers,
                body,
                query
            };
        }
    });
}

function paramsExtractor({
    baseUrl
}) {
    return (callApi) => {
        let {
            method,
            path,
            apiType,
            query,
            body,
            // url,
            headers,
            successType,
            sendingType,
            errorType,
            afterSuccess,
            afterError
        } = callApi;

        // url = url || `${baseUrl}${path}`;

        return {
            method,
            baseUrl,
            path,
            apiType,
            query,
            body,
            headers,
            successType,
            sendingType,
            errorType,
            afterSuccess,
            afterError
        };

    };
}

// prevent console unhandle rejection warning
window.addEventListener("unhandledrejection", e => {
    e.preventDefault();
});
