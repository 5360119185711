import React, { Component } from 'react';
import { connect } from 'react-redux';
import { IntlProvider, addLocaleData } from 'react-intl';
import { compose } from 'redux';
import bindActionCreatorHoc from './bindActionCreatorHoc';
import en from 'react-intl/locale-data/en';
import zh from 'react-intl/locale-data/zh';
import ja from 'react-intl/locale-data/ja';
import languages from '../languages';

addLocaleData([...en, ...zh, ...ja]);

class IntlWrapper extends Component {

  componentWillMount() {
    if (this.props.isRoute) {
      const { f_language } = this.props;
      this.props.localeAction.changeLang({
        messages: f_language.f_messages,
        f_locale: f_language.f_locale,
      });
    }
  }

  render() {
    const { messages, f_locale } = this.props;

    if (f_locale) {
      const locale = languages[f_locale].locale;
      return (
        <IntlProvider locale={locale} messages={messages}>
          {this.props.children}
        </IntlProvider>
      );
    }

    return null;
  }
}

function mapStateToProps(state) {
  return {
    f_locale: state.locale.f_locale,
    messages: state.locale.messages,
    updateTime: state.locale.updateTime
  };
}

export default compose(
  bindActionCreatorHoc,
  connect(mapStateToProps)
) (IntlWrapper);
