import { Constants } from '../actions/locale';

const initialState = {
    f_locale: '',
    messages: {},
    updateTime: null
};

export default function localeReducer(state = initialState, action) {
    switch (action.type) {
        case Constants.changeLang:
            return {
                ...state,
                messages: action.messages,
                f_locale: action.f_locale,
                updateTime: new Date()
            };
        default:
            return state;
    }
}
