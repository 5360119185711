import { Constants } from '../actions/store';

const initialState = {
    storeInfo: null,
    storeIntegration: null,
    settingFunction: null,
    foodPreserveTags: null,
    businessDelivery: [],
    integrationTypes: [],
    importSeat: [],
    storeSeat: [],
    currentBusiness: null,
    template: [],
    carouselImage: [],
    seatOrderTotal: null,
    seatReorder: null,
    childSeat: null,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case Constants.getStoresInfoSuccess:
            return {
                ...state,
                storeInfo: action.response
            };
        case Constants.updateStoresInfoSuccess:
            return {
                ...state,
            };
        case Constants.getStoresIntegrationSuccess:
            return {
                ...state,
                storeIntegration: action.response
            };
        case Constants.getSettingFunctionSuccess:
            return {
                ...state,
                settingFunction: action.response
            };
        case Constants.getFoodPreserveTagsSuccess:
            return {
                ...state,
                foodPreserveTags: action.response
            };
        case Constants.importFoodPreserveTagsSuccess:
            return {
                ...state,
            };
        case Constants.getBusinessDeliverySuccess:
            return {
                ...state,
                businessDelivery: action.response
            };
        case Constants.getIntegrationTypeListSuccess:
            return {
                ...state,
                integrationTypes: action.response
            };
        case Constants.importSeatSuccess:
            return {
                ...state,
                importSeat: action.response
            };
        case Constants.getSeatSuccess:
            return {
                ...state,
                storeSeat: action.response
            };
        case Constants.getCurrentBusinessSuccess:
            return {
                ...state,
                currentBusiness: action.response
            };
        case Constants.getTemplateSuccess:
            return {
                ...state,
                template: action.response
            };
        case Constants.getCarouselImageSuccess:
            return {
                ...state,
                carouselImages: action.response
            };
        case Constants.changeCarouselImageSuccess:
            return {
                ...state,
                carouselImages: action.response
            };
        case Constants.swapCarouselImageSuccess:
            return {
                ...state,
                carouselImages: action.response
            };
        case Constants.deleteCarouselImageSuccess:
            return {
                ...state,
                carouselImages: action.response
            };
        case Constants.getSeatOrderSuccess:
            return {
                ...state,
                seatOrderTotal: action.response
            };
        case Constants.getChildSeatSuccess:
            return {
                ...state,
                childSeat: action.response
            };
        case Constants.updateStoreSeatOrderSuccess:
            return {
                ...state,
            };
        case Constants.updateSeatEnableSuccess:
            return {
                ...state,
            };
        default:
            return state;
    }
}
