import { CALL_API } from '../libraries/middleware';
import actionCreator from '../libraries/actionCreator';

interface BindingObject {
    storeId: number;
    serialNumber: string;
    mac: string;
    model: string;
    name: string;
    no: string;
}

interface TaiwanPayKey {
    storeId: number;
    merchantId: string;
    terminalId: string;
}

export const { Actions, Constants } = actionCreator({
    getPaymentMethod() {
        return {
            [CALL_API]: {
                method: 'GET',
                path: '/payment/method'
            }
        }
    },

    addChangeDeviceAccessory(formData: object) {
        return {
            [CALL_API]: {
                method: 'POST',
                path: '/device/portal/accessory',
                body: formData
            }
        }
    },

    deleteDeviceAccessory(formData: object) {
        return {
            [CALL_API]: {
                method: 'DELETE',
                path: '/device/portal/accessory',
                body: formData
            }
        }
    },

    deviceBinding(formData: BindingObject) {
        const { storeId, serialNumber, mac, model, name, no } = formData;
        return {
            [CALL_API]: {
                method: 'POST',
                path: `/store/${storeId}/bind`,
                body: {
                    sn: serialNumber,
                    mac: mac,
                    model: model,
                    name: name,
                    no: no,
                }
            }
        }
    },

    getMaDeviceByDevice(deviceId: number) {
        return {
            [CALL_API]: {
                method: 'GET',
                path: `/device/${deviceId}/ma`,
            }
        }
    },

    getTaiwanPayKey(taiwanPayKey: TaiwanPayKey) {
        const { storeId, merchantId, terminalId } = taiwanPayKey;
        return {
            [CALL_API]: {
                method: 'POST',
                path: `/store/${storeId}/taiwan-pay/key`,
                body: {
                    merchantId: merchantId,
                    terminalId: terminalId,
                }
            }
        }
    },

    getDeviceByStoreId(storeId: number) {
        return {
            [CALL_API]: {
                method: 'GET',
                path: `/device/store/${storeId}`,
            }
        }
    }
});
