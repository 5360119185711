import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Actions as authActionCreators } from "../actions/auth";
import { Actions as commonActionCreators } from "../actions/common";
import { Actions as localeActionCreators } from "../actions/locale";
import { Actions as reportActionCreators } from "../actions/report";
import { Actions as orderActionCreators } from "../actions/order";
import { Actions as dashboardActionCreators } from "../actions/dashboard";
import { Actions as menuActionCreators } from "../actions/menu";
import { Actions as notificationActionCreators } from "../actions/notification";
import { Actions as deviceActionCreators } from "../actions/device";
import { Actions as storeActionCreators } from "../actions/store";
import { Actions as departmentActionCreators } from "../actions/department";
import { Actions as tagActionCreators } from "../actions/tag";
import { Actions as ruleActionCreators } from "../actions/rule";
import { Actions as couponActionCreators } from "../actions/coupon";
import { Actions as pointActionCreators } from "../actions/point";
import { Actions as ingredientActionCreators } from "../actions/ingredient";
import { Actions as employeeActionCreators } from "../actions/employee";
import { Actions as employeeAttendanceActionCreators} from "../actions/employeeAttendance";
import { Actions as eventActionCreators } from "../actions/event";

export default function bindActionCreatorHoc(WrappedComponent) {
  class Wrapper extends Component {
    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  function mapDispatchToProps(dispatch) {
    return {
      authAction: bindActionCreators(authActionCreators, dispatch),
      commonAction: bindActionCreators(commonActionCreators, dispatch),
      localeAction: bindActionCreators(localeActionCreators, dispatch),
      reportAction: bindActionCreators(reportActionCreators, dispatch),
      orderAction: bindActionCreators(orderActionCreators, dispatch),
      dashboardAction: bindActionCreators(dashboardActionCreators, dispatch),
      menuAction: bindActionCreators(menuActionCreators, dispatch),
      notificationAction: bindActionCreators(
        notificationActionCreators,
        dispatch
      ),
      deviceAction: bindActionCreators(deviceActionCreators, dispatch),
      storeAction: bindActionCreators(storeActionCreators, dispatch),
      departmentAction: bindActionCreators(departmentActionCreators, dispatch),
      tagAction: bindActionCreators(tagActionCreators, dispatch),
      ruleAction: bindActionCreators(ruleActionCreators, dispatch),
      couponAction: bindActionCreators(couponActionCreators, dispatch),
      pointAction: bindActionCreators(pointActionCreators, dispatch),
      ingredientAction: bindActionCreators(ingredientActionCreators, dispatch),
      employeeAction: bindActionCreators(employeeActionCreators, dispatch),
      employeeAttendanceAction: bindActionCreators(
        employeeAttendanceActionCreators,
        dispatch
      ),
      eventAction: bindActionCreators(eventActionCreators, dispatch),
    };
  }

  return connect(null, mapDispatchToProps)(Wrapper);
}
