import { Constants } from '../actions/device';

const initialState = {
    paymentMethod: '',
};


export default function (state = initialState, action) {
    switch (action.type) {
        case Constants.getPaymentMethodSuccess:
            return {
                ...state,
                paymentMethod: action.response
            };
        default:
            return state;
    }
}
