import { CALL_API } from '../libraries/middleware';
import actionCreator from '../libraries/actionCreator';


export const { Actions, Constants } = actionCreator({

    getBusinessRules() {
        return {
            [CALL_API]: {
                method: 'GET',
                path: `/rule`
            }
        }
    },

    saveBusinessRule(data: object) {
        return {
            [CALL_API]: {
                method: 'POST',
                path: `/rule`,
                body: data,
            }
        };
    },

    deleteBusinessRule(id: number) {
        return {
            [CALL_API]: {
                method: 'DELETE',
                path: `/rule/${id}`,
            }
        };
    },

    swapBusinessRules(data: object) {
        return {
            [CALL_API]: {
                method: 'PUT',
                path: `/rule/priority`,
                body: data
            }
        }
    },

    updateStoreRule(data: object, id: number) {
        return {
            [CALL_API]: {
                method: 'POST',
                path: `/rule/store/${id}`,
                body: data
            }
        }
    },

    updateStoreRuleByRule(data: object, id: number) {
        return {
            [CALL_API]: {
                method: 'POST',
                path: `/rule/store/rule/${id}`,
                body: data
            }
        }
    },

    getProductRule() {
        return {
            [CALL_API]: {
                method: 'GET',
                path: `/rule/product/rule`
            }
        }
    },

    saveProductRule(data: object) {
        return {
            [CALL_API]: {
                method: 'POST',
                path: `/rule/product/rule/save`,
                body: data,
            }
        };
    },

    deleteProductRule(id: number) {
        return {
            [CALL_API]: {
                method: 'DELETE',
                path: `/rule/product/rule/${id}`,
            }
        };
    },


});