import { CALL_API } from '../libraries/middleware';
import actionCreator from '../libraries/actionCreator';

interface OrdersQueryObject {
    store: number;
    queryDate: string;
}

interface OrdersWithFilterQueryObject {
    store: number;
    queryDate: string;
    preorderTime: boolean;
}

interface OrderTimeQueryObject {
    store: number;
    startDate: string;
    endDate: string;
}

interface MonthlyOrderQueryObject {
    store: number;
    department: string;
    startDate: string;
    endDate: string;
}

interface OrderQueryObject {
    store: number;
    orderUuid: string;
}

interface OrderTimeQueryObject {
    storeIds: object;
    startDate: string;
    endDate: string;
}

export const { Actions, Constants } = actionCreator({

    getOrderReportList(formData: OrdersWithFilterQueryObject) {
        const { store, queryDate, preorderTime } = formData;
        return {
            [CALL_API]: {
                method: 'GET',
                path: `/report/store/${store}/order`,
                query: {
                    date: queryDate,
                    preorderTimeFirst: preorderTime
                }
            }
        };
    },
    downloadOrderTime(formData: OrderTimeQueryObject) {
        const { store, startDate, endDate } = formData;
        return {
            [CALL_API]: {
                method: 'GET',
                path: `/order/item-time/${store}`,
                query: {
                    startDate: startDate,
                    endDate: endDate
                },
                apiType: 'binary'
            }
        };
    },
    downloadOrderTimeByStores(formData: OrderTimeQueryObject) {
        const { storeIds, startDate, endDate } = formData;
        return {
            [CALL_API]: {
                method: 'POST',
                path: `/order/item-time/stores`,
                body: storeIds,
                query: {
                    startDate: startDate,
                    endDate: endDate
                },
                apiType: 'binary'
            }
        };
    },
    downloadMonthlyOrderReport(formData: MonthlyOrderQueryObject) {
        const { store, department, startDate, endDate } = formData;
        return {
            [CALL_API]: {
                method: 'GET',
                path: `/report/store/${store}/monthly`,
                query: {
                    department: department,
                    startDate: startDate,
                    endDate: endDate
                },
                apiType: 'binary'
            }
        };
    },
    getOrderReport(formData: OrderQueryObject) {
        const { store, orderUuid } = formData;
        return {
            [CALL_API]: {
                method: 'GET',
                path: `/report/store/${store}/order/${orderUuid}`
            }
        };
    },
    refundInvoice(orderUuid: string) {
        return {
            [CALL_API]: {
                method: 'GET',
                path: `/payment/refund`,
                query: {
                    orderUuid: orderUuid
                }
            }
        };
    },
    reissueInvoice(orderUuid: string) {
        return {
            [CALL_API]: {
                method: 'GET',
                path: `/payment/reissue`,
                query: {
                    orderUuid: orderUuid
                }
            }
        };
    },

});
