import 'react-app-polyfill/ie9'; // For IE 9-11 support
import 'react-app-polyfill/ie11'; // For IE 11 support
import './polyfill'
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import request from 'superagent';
import languages from './languages';
import * as serviceWorker from './serviceWorker';

export var f_locale = (localStorage.getItem('lang') || '').toLowerCase();
if (!f_locale || !languages[f_locale]) {
    if (window.navigator.languages) {
        f_locale = (window.navigator.languages[0]).toLowerCase();
    } else {
        f_locale = (window.navigator.userLanguage || window.navigator.language).toLowerCase();
    }
    if (!languages[f_locale]) {
        f_locale = 'en-us';
    }
    localStorage.setItem('lang', f_locale);
}

const startApp = () => {
    return new Promise((resolve, reject) => {
        request.get('/locale/' + f_locale + '.json')
            .set('Accept', 'application/json')
            .end((err, res) => {
                if (err) {
                    throw err;
                } else if (typeof (res) === 'undefined' || !res.ok) {
                    alert('No locale file can be loaded.');
                }

                const f_messages = JSON.parse(res.text);
                ReactDOM.render(<App f_messages={f_messages} />, document.getElementById('root'));
            });
    });
};
startApp();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
