export default {
    "en-us": {
        "locale": "en",
        "description": "American English",
        "enabled": true,
        "flagIcon": "flag-icon-us"
    },
    "zh-tw": {
        "locale": "zh",
        "description": "繁體中文",
        "enabled": true,
        "flagIcon": "flag-icon-tw"
    },
    "ja-jp": {
        "locale": "ja",
        "description": "日本語",
        "enabled": true,
        "flagIcon": "flag-icon-jp"
    }
}
