import { Constants } from '../actions/order';

const initialState = {
    orderListData: []
};

export default function (state = initialState, action) {
    switch (action.type) {
        case Constants.getOrderReportListSuccess:
            return {
                ...state,
                orderListData: action.response
            };
        default:
            return state;
    }
}
