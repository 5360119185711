import { Constants } from '../actions/report';

const initialState = {
    stores: null,
    devices: null,
    amountReportType: 'daily',
    amountReportData: [],
    itemAmountReportData: [],
    eventStatsReportData: [],
    eventListReportData: [],
    customerProblemsReportData: [],
    storeStatusData: [],
    onlineStores: []
};

export default function (state = initialState, action) {
    switch (action.type) {
        case Constants.getStoresSuccess:
            return {
                ...state,
                stores: action.response
            };
        case Constants.getDevicesSuccess:
            return {
                ...state,
                devices: action.response
            };
        case Constants.getAmountReportSuccess:
            return {
                ...state,
                amountReportType: action.reportType,
                amountReportData: action.response
            };
        case Constants.getItemAmountReportSuccess:
            return {
                ...state,
                itemAmountReportData: action.response
            };
        case Constants.getEventStatsReportSuccess:
            return {
                ...state,
                eventStatsReportData: action.response
            };
        case Constants.getEventListReportSuccess:
            return {
                ...state,
                eventListReportData: action.response
            };
        case Constants.getCustomerProblemsReportSuccess:
            return {
                ...state,
                customerProblemsReportData: action.response
            };
        case Constants.getStoreStatusSuccess:
            return {
                ...state,
                storeStatusData: action.response
            };
        case Constants.requestDianosticReportSuccess:
            return{
                ...state
            }
        case Constants.getListOnlineStoreSuccess:
            return{    
            ...state,
            onlineStores: action.response
            }
        default:
            return state;
    }
}
