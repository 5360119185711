import { CALL_API } from '../libraries/middleware';
import actionCreator from '../libraries/actionCreator';

interface EventsListObject {
    storeIds: string;
    types: string;
    startDate: string;
    endDate: string;
    keyword: string;
    page: number;
    size: number;
}

interface EventSearchRequst {
    storeIds: string;
    messages: string;
    startDate: string;
    endDate: string;
    page: number;
    size: number;
}

export const { Actions, Constants } = actionCreator({

    getEventTypesInfo() {
        return {
            [CALL_API]: {
                method: 'GET',
                path: `/event/type`,
            }
        }
    },

    getEventList(formData: EventsListObject) {
        const { storeIds, types, startDate, endDate, keyword, page, size } = formData;
        const body = {
            storeIds: storeIds,
            startDate: startDate,
            endDate: endDate,
            types: types,
            keyword: keyword,
        }
        return {
            [CALL_API]: {
                method: 'POST',
                path: `/event/list?page=${page}&size=${size}`,
                body: body
            }
        }
    },

    getEventListByMessage(formData:EventSearchRequst) {
        const {storeIds, messages, startDate, endDate, page, size} = formData;
        const body = {
            storeIds: storeIds,
            startDate: startDate,
            endDate: endDate,
            messages: messages,
        }
        return {
            [CALL_API]: {
                method: 'POST',
                path: `/event/list/message?page=${page}&size=${size}`,
                body: body
            }
        }
    }
});