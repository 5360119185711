import { Constants } from "../actions/dashboard";

const initialState = {
  queryStartDate: null,
  dashboardData: []
};

export default function(state = initialState, action) {
  switch (action.type) {
    case Constants.getStoreSuccess:
      return {
        ...state,
        queryStartDate: action.startDate,
        dashboardData: action.response,
      };
    case Constants.getAllStoresSuccess:
      return {
        ...state,
        queryStartDate: action.startDate,
        dashboardData: action.response,
      };
    default:
      return state;
  }
}
