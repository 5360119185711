import { Constants } from '../actions/menu';

const initialState = {
    langs: '',
    importMenu: '',
    allMenu: '',
    allMenuSchedule: '',
    menuOfStore: '',
    category: '',
    item: '',
    attribute: '',
    option: '',
    menuGroups: [],
};

export default function (state = initialState, action) {
    switch (action.type) {
        case Constants.getAllLangsSuccess:
            return {
                ...state,
                langs: action.response
            };
        case Constants.importMenuSuccess:
            return {
                ...state,
                importMenu: action.response
            };
        case Constants.getAllMenuSuccess:
            return {
                ...state,
                allMenu: action.response
            };
        case Constants.getMenuScheduleSuccess:
            return {
                ...state,
                allMenuSchedule: action.response
            };
        case Constants.getMenuScheduleOfStoreSuccess:
            return {
                ...state,
                menuOfStore: action.response
            };
        case Constants.getMenuCategorySuccess:
            return {
                ...state,
                category: action.response,
                item: '',
                attribute: '',
            };
        case Constants.updateCategorySuccess:
            return {
                ...state,
                category: action.response.data,
                item: '',
                attribute: '',
            };
        case Constants.getMenuItemSuccess:
            return {
                ...state,
                item: action.response,
                attribute: '',
            };
        case Constants.getCategoryItemSuccess:
            return {
                ...state,
                item: action.response,
                attribute: '',
            };
        case Constants.getMenuAttributeSuccess:
            return {
                ...state,
                attribute: action.response,
                option: ''
            };
        case Constants.getItemAttributeSuccess:
            return {
                ...state,
                attribute: action.response,
                option: ''
            };
        case Constants.getAttributeOptionSuccess:
            return {
                ...state,
                option: action.response
            };
        case Constants.getUncategorizedItemsSuccess:
            return {
                ...state,
                item: action.response,
            };
        case Constants.getBusinessMenuGroupSuccess:
            return {
                ...state,
                menuGroups: action.response,
            };

        default:
            return state;
    }
}
