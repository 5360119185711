import { CALL_API } from '../libraries/middleware';
import actionCreator from '../libraries/actionCreator';


interface TagObject{
    businessId: number;
    tagId: number;
    body: object;
}

export const { Actions, Constants } = actionCreator({

    getBusinessTags() {
        return {
            [CALL_API]: {
                method: 'GET',
                path: `/tag`
            }
        }
    },

    addBusinessTags(formData: TagObject) {
        const { body } = formData;
        return {
            [CALL_API]: {
                method: 'POST',
                path: `/tag`,
                body: body
            }
        }
    },
    updateBusinessTags(formData: TagObject) {
        const { body } = formData;
        return {
            [CALL_API]: {
                method: 'PUT',
                path: `/tag`,
                body:body
            }
        }
    },
    checkBeforeDeleteBusinessTags(formData: TagObject) {
        const { tagId } = formData;
        return {
            [CALL_API]: {
                method: 'POST',
                path: `/tag/check/${tagId}`,
            }
        }
    },
    deleteBusinessTags(formData: TagObject) {
        const { tagId } = formData;
        return {
            [CALL_API]: {
                method: 'DELETE',
                path: `/tag/${tagId}`,
            }
        }
    },
    swapTags(formData: TagObject) {
        const { body } = formData;
        return {
            [CALL_API]: {
                method: 'PUT',
                path: `/tag/position`,
                body: body
            }
        }
    },

});
