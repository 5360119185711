import { CALL_API } from "../libraries/middleware";
import actionCreator from "../libraries/actionCreator";

interface ImportObject {
  storeId: number;
  body: object;
}

export const { Actions, Constants } = actionCreator({
  getDepartments(storeId:String) {
    return {
      [CALL_API]: {
        method: "GET",
        path: `/store/${storeId}/department`,
        query: {
        },
      },
    };
  },
  updateDepartment(department:object) {
    return {
      [CALL_API]: {
        method: "POST",
        path: `/store/department`,
        body:department,
      },
    };
  },
  deleteDepartment(department:object) {
    return {
      [CALL_API]: {
        method: "DELETE",
        path: `/store/department`,
        body:department,
      },
    };
  },
  importDepartment(formData: ImportObject) {

    const { storeId, body } = formData;

    return {
        [CALL_API]: {
            method: 'POST',
            path: `/store/${storeId}/department/import`,
            body: body,
            apiType: 'multipart'
        },
    };
  },
  downloadDepartment(storeId:String) {

    return {
        [CALL_API]: {
            method: 'POST',
            path: `/store/${storeId}/export`,
            apiType: 'binary'
        },
    };
},
  editPinCode(formData: ImportObject) {
    const {storeId,body}=formData;
    return {
        [CALL_API]: {
            method: 'POST',
            path: `/store/${storeId}/staffpin`,
            body:body,
        },
    };
  },
});