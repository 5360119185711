import { combineReducers } from 'redux';
import auth from './auth';
import common from './common';
import locale from './locale';
import report from './report';
import order from './order';
import dashboard from './dashboard';
import menu from './menu';
import notification from './notification';
import device from './device';
import store from './store';

const appReducer = combineReducers({
    auth,
    common,
    locale,
    report,
    order,
    dashboard,
    menu,
    notification,
    device,
    store,
});
const rootReducer = (state, action) => {
    // Clear all data in redux store to initial.
    if (action.type === "logout") {
        state = undefined;
        window.location.reload();
    }
    return appReducer(state, action);
};

// const rootReducer = combineReducers({
//     auth,
//     common,
//     locale,
//     report,
//     order,
//     dashboard,
//     menu,
//     notification,
//     device,
//     store,
// });


export default rootReducer;
