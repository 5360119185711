import { CALL_API } from '../libraries/middleware';
import actionCreator from '../libraries/actionCreator';

interface notificationObject {
    storeId: string;
    notification: object;
    storeNotificationId: string;
}


export const { Actions, Constants } = actionCreator({
    getNotifications(formData: notificationObject) {
        const { storeId } = formData;
        return {
            [CALL_API]: {
                method: 'GET',
                path: `/notification/store/${storeId}`
            }
        };
    },
    putNotification(formData: notificationObject) {
        const { notification, storeNotificationId } = formData;
        return {
            [CALL_API]: {
                method: 'PUT',
                path: `/notification/${storeNotificationId}`,
                body: notification
            }
        };
    },
    postNotification(formData: notificationObject) {
        const { notification, storeId } = formData;
        return {
            [CALL_API]: {
                method: 'POST',
                path: `/notification/store/${storeId}`,
                body: notification
            }
        };
    },
    deleteNotification(formData: notificationObject) {
        const { storeNotificationId } = formData;
        return {
            [CALL_API]: {
                method: 'DELETE',
                path: `/notification/${storeNotificationId}`
            }
        };
    },
    getBusinessChannel() {
        return {
            [CALL_API]: {
                method: 'GET',
                path: `/business/channel`,
            }
        };
    },
    createBusinessChannel(formData: notificationObject) {
        const { notification } = formData;
        return {
            [CALL_API]: {
                method: 'POST',
                path: `/business/channel`,
                body: notification
            }
        };
    },
    editBusinessChannel(formData: notificationObject) {
        const { notification } = formData;
        return {
            [CALL_API]: {
                method: 'PUT',
                path: `/business/channel`,
                body: notification
            }
        };
    },
    deleteBusinessChannel(formData: notificationObject) {
        const { notification } = formData;
        return {
            [CALL_API]: {
                method: 'DELETE',
                path: `/business/channel`,
                body: notification
            }
        };
    }
});
