import { CALL_API } from '../libraries/middleware';
import actionCreator from '../libraries/actionCreator';
import { stream } from 'exceljs';

interface EmployeeObject  {
    storeId: string;
    body: object;
}
export const { Actions, Constants } = actionCreator({
    getEmployees(storeId: number) {
        return {
            [CALL_API]: {
                method: 'GET',
                path: `/employee/store/${storeId}`
            }
        };
    },
    editEmployee(formData: EmployeeObject) {
        const { storeId, body } = formData;
        return {
            [CALL_API]: {
                method: 'POST',
                path: `/employee/store/${storeId}`,
                body: body
            }
        };
    },
    deleteEmployee(id: number) {
        return {
            [CALL_API]: {
                method: 'DELETE',
                path: `/employee/${id}`
            }
        };
    },
    importEmployee(formData: any, storeId: number) {
        return {
            [CALL_API]: {
                method: 'POST',
                path: `/employee/import/${storeId}`,
                body: formData,
                apiType: 'multipart'
            }
        }
    },
});