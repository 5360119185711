import * as _ from 'lodash';
import { CALL_API } from './middleware';

class Result<T> {
    Constants!: {
        [actionType in keyof T]: string;
    } & { [key: string]: string };
    Actions!: T;
}

export default <T>(...groups: any[]): Result<T> => {
    return groups.reduce(combineGroups, []).reduce(formatActions, {
        Constants: {},
        Actions: {}
    });
}

function combineGroups(result: any, group: any) {
    if (!_.isObject(group)) {
        throw new Error(`Group of actions must be plain object!`)
    }
    return result.concat(Object.keys(group)
        .map(key => ({
            name: key,
            fn: group[key]
        })));
}

function formatActions({
    Constants,
    Actions
}: any, action: any) {
    if (Constants.hasOwnProperty(action.name)) {
        throw new Error(`Action ${action.name} already exist!`)
    }

    const newConstants = Object.assign(Constants, {
        [action.name]: action.name
    });

    newConstants[`${action.name}Success`] = `${action.name}Success`;

    return {
        Constants: newConstants,
        Actions: Object.assign(Actions, {
            [action.name]: (...args: any[]) => {
                const result = action.fn(...args);

                if (result[CALL_API]) {
                    return {
                        ...result,
                        [CALL_API]: {
                            ...result[CALL_API],
                            sendingType: {
                                ...result[CALL_API].sendingType,
                                type: action.name
                            },
                            successType: {
                                ...result[CALL_API].successType,
                                type: `${action.name}Success`
                            }
                        }
                    };
                } else {
                    // thunk
                    if (_.isFunction(result)) {
                        return result;
                    } else {
                        return { type: action.name, ...result };
                    }
                }
            }
        })
    };
}
