import {CALL_API} from '../libraries/middleware';
import actionCreator from '../libraries/actionCreator';
import {stream} from 'exceljs';

interface QueryEmployeeAttendanceLog {
    storeId: number,
    empId: number[],
    startDate: string,
    endDate: string,
    isError: boolean,
    isDelete: boolean
}
interface EmployeeAttendanceLogVo {
    id: number| null,
    storeId: number,
    employeeId: number,
    punchInTime: string,
    punchOutTime: string,
    overtime: boolean,
    noBreakTime: boolean,
    note: string,
}

export const {Actions, Constants} = actionCreator({
    getEmployeeAttendanceLog(formData: QueryEmployeeAttendanceLog) {
        return {
            [CALL_API]: {
                method: 'GET',
                path: '/emp-attendance',
                query: formData
            }
        }
    },
    getEmployeeAttendanceLogById(id: string) {
        return {
            [CALL_API]: {
                method: 'GET',
                path: '/emp-attendance/' + id
            }
        }
    },

    punch(formData: EmployeeAttendanceLogVo) {
        const {storeId} = formData;
        return {
            [CALL_API]: {
                method: 'POST',
                path: '/emp-attendance/' + storeId + '/punch',
                body: formData
            }
        }
    },
    deleteEmployeeAttendanceLog(id: number) {
        return {
            [CALL_API]: {
                method: 'DELETE',
                path: '/emp-attendance/' + id,
            }
        }
    }
});