import { CALL_API } from '../libraries/middleware';
import actionCreator from '../libraries/actionCreator';

interface StoreQueryObject {
    storeId: number;
    role: string;
    password: string;
}

export const { Actions, Constants } = actionCreator({

    login(formData: object) {
        return {
            [CALL_API]: {
                method: 'POST',
                body: formData,
                path: '/auth/login',
                headers: {
                    'X-Authorization': undefined
                }
            }
        };
    },

    logout() {
        return {};
    },

    refreshToken(inputToken: string) {
        return {
            [CALL_API]: {
                method: 'POST',
                path: '/auth/token',
                headers: {
                    'X-Authorization': inputToken ? `Bearer ${inputToken}` : ''
                }
            }
        };
    },

    setLoginMessage(id: string) {
        return {
            id
        };
    },

    clearLoginMessage() {
        return {};
    },

    setPermission(formData: StoreQueryObject) {
        const { storeId, role, password } = formData;
        return {
            [CALL_API]: {
                method: 'POST',
                path: `/store/${storeId}/permission`,
                query: {
                    role: role,
                    password: password
                }
            }
        };
    },

    getCurrentUser(){
        return {
            [CALL_API]: {
                method: 'GET',
                path: `/user/current`,
            }
        };
    },

    getUsers(){
        return {
            [CALL_API]: {
                method: 'GET',
                path: `/user/all`,
            }
        };
    },

    saveUser(data: object){
        return {
            [CALL_API]: {
                method: 'POST',
                path: `/user`,
                body: data,
            }
        };
    },

    deleteUser(id: number){
        return {
            [CALL_API]: {
                method: 'DELETE',
                path: `/user/${id}`,
            }
        };
    },

    getAllAuth(){
        return {
            [CALL_API]: {
                method: 'GET',
                path: `/user/auth`
            }
        };
    },

    getRole(){
        return {
            [CALL_API]: {
                method: 'GET',
                path: `/user/role`
            }
        }
    },

    saveRole(data: object){
        return {
            [CALL_API]: {
                method: 'POST',
                path: `/user/role`,
                body: data,
            }
        };
    },

    deleteRole(id: number){
        return {
            [CALL_API]: {
                method: 'DELETE',
                path: `/user/role/${id}`,
            }
        };
    },

});
