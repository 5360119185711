export const APIConfig = {

    '/auth/login': {
        'POST': 'login.json'
    },

    '/store': {
        'GET': 'stores.json'
    },

    '/device': {
        'GET': 'devices.json'
    },

    '/report/store/1/amount/daily': {
        'GET': 'amountReportDaily.json'
    },

    '/report/store/1/amount/weekly': {
        'GET': 'amountReportWeekly.json'
    },

    '/report/store/1/amount/monthly': {
        'GET': 'amountReportMonthly.json'
    },

    '/report/store/1/amount/item': {
        'GET': 'itemAmountReport.json'
    },

    '/order': {
        'GET': 'orderList.json'
    },

    '/dashboard': {
        'GET': 'dashboardData.json'
    }
};
