import actionCreator from '../libraries/actionCreator';

export const { Actions, Constants } = actionCreator({

    openLoadingAnimation() {
        return {};
    },

    closeLoadingAnimation() {
        return {};
    },

    changeWindowSize() {
        return {
            windowInnerWidth: window.innerWidth
        };
    }
});
