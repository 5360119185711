import {CALL_API} from '../libraries/middleware';
import actionCreator from '../libraries/actionCreator';

interface AmountQueryObject {
  store: number;
  reportType: string;
  startDate: string;
  endDate: string;
}

interface ItemAmountQueryObject {
  store: number;
  startDate: string;
  endDate: string;
  method: string;
  serveType: string;
  startTime: string;
  endTime: string;
  preorderTimePriority: boolean;
}

interface ItemAmountQueryObjectV2 {
  store: number;
  startDate: string;
  endDate: string;
  preorderTimePriority: boolean;
  byMenu: boolean;
  serve: string[];
  sources: string[];
}

interface EventQueryObject {
  locale: string;
  startDate: string;
  endDate: string;
}

interface CustomerProblemObject {
  uuid: string;
}

interface StoreStatusObject {
  store: string;
}

interface RequestDianosticObject {
  store: string;
  startDate: string;
  endDate: string;
  prefs: boolean;
  db: boolean;
  props: boolean;
}

interface SheetQueryObject {
  store: number;
  date: string;
  body: object;
}

interface SalesReportObject {
  store: number;
  queryDate: string;
  language: string;
}

interface SalesReportWithFilterObject {
  store: number;
  queryDate: string;
  language: string;
  preorderTime: boolean;
}

interface RangeTimeQueryObject {
  store: number;
  startDate: string;
  endDate: string;
}

interface StoresRangeTimeQueryObject {
  storeIds: object;
  startDate: string;
  endDate: string;
}

interface ReportStoreRuleObject {
  body: object;
}

interface PaymentTotalQueryObject {
  storeIds: object;
  store: number;
  startDate: string;
  endDate: string;
}

interface BusinessReportQueryObject {
  storeIds: [number];
  startDate: string;
  endDate: string;
}

interface BusinessReportByServeTypeQueryObject {
  storeIds: [number];
  startDate: string;
  endDate: string;
  serve: [string]
}

interface BusinessReportBySourceQueryObject {
  storeIds: [number];
  startDate: string;
  endDate: string;
  source: [string]
}

interface MiscRecordObject {
  storeId: number;
  startDate: string;
  endDate: string;
}

interface BusinessReportByPaymentQueryObject {
  storeIds: [number];
  startDate: string;
  endDate: string;
  payment: [string];

  paymentDetails: [string]
}

export const {Actions, Constants} = actionCreator({

  getStores() {
    return {
      [CALL_API]: {
        method: 'GET',
        path: '/store'
      }
    }
  },

  getSimpleStores() {
    return {
      [CALL_API]: {
        method: 'GET',
        path: '/store/simple'
      }
    }
  },

  getDevices() {
    return {
      [CALL_API]: {
        method: 'GET',
        path: '/device'
      }
    }
  },

  getAmountReport(formData: AmountQueryObject) {
    const {store, reportType, startDate, endDate} = formData;
    return {
      reportType,
      [CALL_API]: {
        method: 'GET',
        path: `/report/store/${store}/amount/${reportType}`,
        query: {
          start: startDate,
          end: endDate
        }
      }
    };
  },

  getItemAmountReport(formData: ItemAmountQueryObject) {
    const {store, startDate, endDate, method, serveType, startTime, endTime, preorderTimePriority} = formData;
    return {
      [CALL_API]: {
        method: 'GET',
        path: `/report/store/${store}/amount/item`,
        query: {
          start: startDate,
          end: endDate,
          withTotal: true,
          method: method,
          serveType: serveType,
          startTime: startTime,
          endTime: endTime,
          preorderTimePriority: preorderTimePriority
        }
      }
    };
  },

  getItemAmountReportV2(formData: ItemAmountQueryObjectV2) {
    const {store, startDate, endDate, preorderTimePriority, byMenu, serve, sources} = formData;
    return {
      [CALL_API]: {
        method: 'GET',
        path: `/report/store/${store}/amount/item/v2`,
        query: {
          start: startDate,
          end: endDate,
          preorderTimePriority,
          byMenu,
          serve,
          sources
        }
      }
    };
  },

  getEventStatsReport(formData: EventQueryObject) {
    const {locale, startDate, endDate} = formData;
    return {
      [CALL_API]: {
        method: 'GET',
        path: `/admin/event/stat`,
        query: {
          locale: locale,
          start: startDate,
          end: endDate
        }
      }
    };
  },

  getEventListReport(formData: EventQueryObject) {
    const {locale, startDate, endDate} = formData;
    return {
      [CALL_API]: {
        method: 'GET',
        path: `/admin/event/list`,
        query: {
          locale: locale,
          start: startDate,
          end: endDate
        }
      }
    };
  },

  downloadTimetable(formData: ItemAmountQueryObject) {
    const {store, startDate, endDate} = formData;
    return {
      [CALL_API]: {
        method: 'GET',
        path: `/store/${store}/check-time-table/export`,
        query: {
          start: startDate,
          end: endDate
        },
        apiType: 'binary'
      }
    };
  },

  getCustomerProblemsReport() {
    return {
      [CALL_API]: {
        method: 'GET',
        path: '/admin/diagnostic?size=50'
      }
    }
  },

  downloadCustomerProblem(formData: CustomerProblemObject) {
    const {uuid} = formData;
    return {
      [CALL_API]: {
        method: 'GET',
        path: `/admin/diagnostic/download/${uuid}`,
        apiType: 'binary'
      }
    };
  },

  getStoreStatus(formData: StoreStatusObject) {
    const {store} = formData;
    return {
      [CALL_API]: {
        method: 'GET',
        path: `/store/${store}/status`
      }
    };
  },

  requestDianosticReport(formData: RequestDianosticObject) {
    const {store, startDate, endDate, prefs, db, props} = formData;
    return {
      [CALL_API]: {
        method: 'GET',
        path: `/store/${store}/diagnostic`,
        query: {
          start: startDate,
          end: endDate,
          prefs: prefs,
          db: db,
          props: props
        }
      }
    };
  },

  getListOnlineStore() {
    return {
      [CALL_API]: {
        method: 'GET',
        path: `/store/online`
      }
    }
  },

  downloadIncomeSheet(formData: SalesReportObject) {
    const {store, queryDate, language} = formData;
    return {
      [CALL_API]: {
        method: 'GET',
        path: `/report/store/${store}/incomeSheet`,
        query: {
          date: queryDate,
          language: language
        },
        apiType: 'binary'
      }
    }
  },

  getSalesReport(formData: SalesReportObject) {
    const {store, queryDate, language} = formData;
    return {
      [CALL_API]: {
        method: 'GET',
        path: `/report/store/${store}/reports`,
        query: {
          date: queryDate,
          language: language
        },
        apiType: 'binary'
      }
    }
  },

  getOrdersReport(formData: SalesReportWithFilterObject) {
    const {store, queryDate, preorderTime} = formData;
    return {
      [CALL_API]: {
        method: 'GET',
        path: `/report/store/${store}/order/export`,
        query: {
          date: queryDate,
          preorderTimeFirst: preorderTime
        },
        apiType: 'binary'
      }
    }
  },

  getRangeOrdersReport(formData: RangeTimeQueryObject) {
    const {store, startDate, endDate} = formData;
    return {
      [CALL_API]: {
        method: 'GET',
        path: `/report/store/${store}/order/range-export`,
        query: {
          startDate: startDate,
          endDate: endDate
        },
        apiType: 'binary'
      }
    }
  },

  getStoresRangeOrdersReport(formData: StoresRangeTimeQueryObject) {
    const {storeIds, startDate, endDate} = formData;
    return {
      [CALL_API]: {
        method: 'POST',
        path: `/report/store/order/range-export`,
        body: storeIds,
        query: {
          startDate: startDate,
          endDate: endDate
        },
        apiType: 'binary'
      }
    }
  },

  downloadCashSheet(formData: SheetQueryObject) {
    const {store, date, body} = formData;
    return {
      [CALL_API]: {
        method: 'POST',
        path: `/report/store/${store}/cashSheet`,
        body: body,
        query: {
          date: date
        },
        apiType: 'binary'
      }
    };
  },

  getSettings(store: number) {
    return {
      [CALL_API]: {
        method: 'GET',
        path: `/report/store/${store}/reserve-setting`
      }
    };
  },

  getPaymentCash(formData: ItemAmountQueryObject) {
    const {store, startDate} = formData;
    return {
      [CALL_API]: {
        method: 'GET',
        path: `/report/store/${store}/payment-cash`,
        query: {
          date: startDate
        }
      }
    };
  },

  getStoreReportRule(storeId: number) {
    return {
      [CALL_API]: {
        method: 'GET',
        path: `/report/store/${storeId}/rule`
      }
    }
  },

  updateStoreReportRule(formData: ReportStoreRuleObject) {

    const {body} = formData;

    return {
      [CALL_API]: {
        method: 'POST',
        path: `/report/store/rule`,
        body: body,
      }
    }
  },

  downloadPaymentTotal(formData: PaymentTotalQueryObject) {
    const {store, startDate, endDate} = formData;
    return {
      [CALL_API]: {
        method: 'GET',
        path: `/report/payment-total/${store}`,
        query: {
          startDate: startDate,
          endDate: endDate
        },
        apiType: 'binary'
      }
    };
  },

  downloadPaymentTotalByStores(formData: PaymentTotalQueryObject) {
    const {storeIds, startDate, endDate} = formData;
    return {
      [CALL_API]: {
        method: 'POST',
        path: `/report/payment-total/stores`,
        body: storeIds,
        query: {
          startDate: startDate,
          endDate: endDate
        },
        apiType: 'binary'
      }
    };
  },

  downloadPaymentTotalOneSheet(formData: PaymentTotalQueryObject) {
    const {store, startDate, endDate} = formData;
    return {
      [CALL_API]: {
        method: 'GET',
        path: `/report/payment-total/one-sheet/${store}`,
        query: {
          startDate: startDate,
          endDate: endDate
        },
        apiType: 'binary'
      }
    };
  },

  downloadPaymentSummary(formData: PaymentTotalQueryObject) {
    const {store, startDate, endDate} = formData;
    return {
      [CALL_API]: {
        method: 'GET',
        path: `/report/payment-summary/${store}`,
        query: {
          startDate: startDate,
          endDate: endDate
        },
        apiType: 'binary'
      }
    };
  },

  downloadPaymentSummaryByStores(formData: PaymentTotalQueryObject) {
    const {storeIds, startDate, endDate} = formData;
    return {
      [CALL_API]: {
        method: 'POST',
        path: `/report/payment-summary/stores`,
        body: storeIds,
        query: {
          startDate: startDate,
          endDate: endDate
        },
        apiType: 'binary'
      }
    };
  },

  downloadInvoiceReport(formData: PaymentTotalQueryObject) {
    const {store, startDate, endDate} = formData;
    return {
      [CALL_API]: {
        method: 'GET',
        path: `/report/store/${store}/invoice/export`,
        query: {
          startDate: startDate,
          endDate: endDate
        },
        apiType: 'binary'
      }
    };
  },

  downloadInvoiceReportByStores(formData: PaymentTotalQueryObject) {
    const {storeIds, startDate, endDate} = formData;
    return {
      [CALL_API]: {
        method: 'POST',
        path: `/report/invoice/export/stores`,
        body: storeIds,
        query: {
          startDate: startDate,
          endDate: endDate
        },
        apiType: 'binary'
      }
    };
  },

  downloadInvoiceDataReport(formData: PaymentTotalQueryObject) {
    const {store, startDate, endDate} = formData;
    return {
      [CALL_API]: {
        method: 'GET',
        path: `/report/store/${store}/invoice-data/export`,
        query: {
          startDate: startDate,
          endDate: endDate
        },
        apiType: 'binary'
      }
    };
  },

  downloadInvoiceDataReportByStores(formData: PaymentTotalQueryObject) {
    const {storeIds, startDate, endDate} = formData;
    return {
      [CALL_API]: {
        method: 'POST',
        path: `/report/invoice-data/export/stores`,
        body: storeIds,
        query: {
          startDate: startDate,
          endDate: endDate
        },
        apiType: 'binary'
      }
    };
  },

  getPromotionReport(body: Object) {

    return {
      [CALL_API]: {
        method: 'GET',
        path: `/report/promotion`,
        query: body,
      }
    }
  },

  downloadPromotionReport(body: Object) {
    return {
      [CALL_API]: {
        method: 'GET',
        path: `/report/promotion/export`,
        query: body,
        apiType: 'binary'
      }
    }
  },

  getBusinessReport(formData: BusinessReportQueryObject) {
    const {storeIds, startDate, endDate} = formData;
    return {
      [CALL_API]: {
        method: 'GET',
        path: '/report/store/business',
        query: {
          storeIds: storeIds,
          start: startDate,
          end: endDate,
        }
      }
    };
  },

  getBusinessReportByServeType(formData: BusinessReportByServeTypeQueryObject) {
    const {storeIds, startDate, endDate, serve} = formData;
    return {
      [CALL_API]: {
        method: 'GET',
        path: '/report/store/businessByServeType',
        query: {
          storeIds: storeIds,
          start: startDate,
          end: endDate,
          serve
        }
      }
    };
  },

  getBusinessReportBySource(formData: BusinessReportBySourceQueryObject) {
    const {storeIds, startDate, endDate, source} = formData;
    return {
      [CALL_API]: {
        method: 'GET',
        path: '/report/store/businessBySource',
        query: {
          storeIds: storeIds,
          start: startDate,
          end: endDate,
          source
        }
      }
    };
  },

  getMiscRecordReport(formData: MiscRecordObject) {
    const {storeId, startDate, endDate} = formData;
    return {
      [CALL_API]: {
        method: 'GET',
        path: `/misc-record/store/${storeId}/report`,
        query: {
          start: startDate,
          end: endDate,
        }
      }
    };
  },

  getBusinessReportByPayment(formData: BusinessReportByPaymentQueryObject) {
    const {storeIds, startDate, endDate, payment, paymentDetails} = formData;
    return {
      [CALL_API]: {
        method: 'GET',
        path: '/report/store/businessByPayment',
        query: {
          storeIds: storeIds,
          start: startDate,
          end: endDate,
          payment,
          paymentDetails
        }
      }
    };
  },

  getCashModulesByDeviceIds(storeId:number, deviceIds: object, startDate: String, endDate: String) {
    return {
      [CALL_API]: {
        method: 'GET',
        path: `/report/store/${storeId}/cash-modules`,
        query: {
          deviceIds: deviceIds,
          start: startDate,
          end: endDate
        }
      }
    };
  },

  downloadCashModules(storeId:number, deviceIds: object, startDate: String, endDate: String) {
    return {
      [CALL_API]: {
        method: 'GET',
        path: `/report/store/${storeId}/cash-modules/export`,
        query: {
          deviceIds: deviceIds,
          start: startDate,
          end: endDate
        },
        apiType: 'binary'
      }
    }
  },

  getCashModulesErrorByDeviceIds(storeId:number, deviceIds: object, startDate: String, endDate: String) {
    return {
      [CALL_API]: {
        method: 'GET',
        path: `/report/store/${storeId}/cash-modules-error`,
        query: {
          deviceIds: deviceIds,
          start: startDate,
          end: endDate
        }
      }
    };
  },

  downloadCashModulesError(storeId:number, deviceIds: object, startDate: String, endDate: String) {
    return {
      [CALL_API]: {
        method: 'GET',
        path: `/report/store/${storeId}/cash-modules-error/export`,
        query: {
          deviceIds: deviceIds,
          start: startDate,
          end: endDate
        },
        apiType: 'binary'
      }
    }
  }
});
