import actionCreator from '../libraries/actionCreator';

export const { Actions, Constants } = actionCreator({

    changeLang(data: object) {
        // locale file
        return {
            ...data
        };
    }
});
