import { CALL_API } from '../libraries/middleware';
import actionCreator from '../libraries/actionCreator';

export const { Actions, Constants } = actionCreator({

    getIngredient() {
        return {
            [CALL_API]: {
                method: 'GET',
                path: `/ingredient`
            }
        }
    },

    updateIngredient(body: object) {
        return {
            [CALL_API]: {
                method: 'POST',
                path: `/ingredient`,
                body: body
            }
        }
    },

    updateIngredientItems(id: string, body: object) {
        return {
            [CALL_API]: {
                method: 'POST',
                path: `/ingredient/${id}/item/update`,
                body: body,
            },
        };
    },

    deleteIngredient(id: string) {
        return {
            [CALL_API]: {
                method: 'DELETE',
                path: `/ingredient/${id}`,
            }
        }
    }

});