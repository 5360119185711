import { CALL_API } from "../libraries/middleware";
import actionCreator from "../libraries/actionCreator";

export const { Actions, Constants } = actionCreator({
  getStore(startDate: string, endDate: string, storeId: number, type: string) {
    return {
      startDate,
      [CALL_API]: {
        method: "GET",
        path: "/dashboard",
        query: {
          start: startDate,
          end: endDate,
          storeId: storeId,
          type: type
          
        },
      },
    };
  },
  getAllStores(startDate: string, endDate: string, type: string) {
    return {
      startDate,
      [CALL_API]: {
        method: "GET",
        path: "/dashboard",
        query: {
          start: startDate,
          end: endDate,
          storeId: null,
          type: type
        },
      },
    };
  },
});
